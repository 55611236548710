import { resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($setup.customerOrderDetailsComponent), {
      key: undefined,
      onDeliveryDateUpdated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deliveryDateUpdated', $event))),
      onEscalationUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('escalationUpdated', $event)))
    }, null, 32)), [
      [_vShow, $setup.isCustomerOrderRoute]
    ]),
    _createVNode(_component_router_view)
  ], 64))
}